import React from "react";
import "./Contact.scss";
import GridContainer from "../GridContainer/GridContainer";
import Input from "../Input/Input";
import Button from "../Button/Button";

// icons
import { ReactComponent as Fb } from "../../assets/svgs/facebook.svg";
import { ReactComponent as Insta } from "../../assets/svgs/instagram.svg";
import { ReactComponent as Linkedin } from "../../assets/svgs/linkedin.svg";
import { ReactComponent as Youtube } from "../../assets/svgs/youtube.svg";
import { classNames } from "../../services/util";

const Contact = () => {
  return (
    <div className="contact">
      <div className="c-header">
        Bring your talent, together with your passion, to make a difference.
      </div>
      <div className="c-desc">
        If you are talented in any areas of artificial intelligence, exicited by
        how artificial intelligence is impacting and transforming the
        professions and prepared to take up new challenges as an opportunity and
        embark on a new career, contact us.
      </div>
      <div className="c-application">
        <span>Application</span>
      </div>
      <div className="form">
        <GridContainer elementMinWidth={300}>
          <Input name="name" placeholder="Name" />
          <Input name="surname" placeholder="Surname" />
          <Input name="phone" placeholder="Phone" />
          <Input name="mail" placeholder="E-Mail" />
        </GridContainer>
        <Input type="textarea" name="letter" placeholder="Cover Letter" />
        <div className="form-submit">
          <Button>Submit</Button>
        </div>
      </div>
    </div>
  );
};

const Contact2 = () => {
  return (
    <div className="contact">
      <div className="c-header contact">
        <div>Let’s create something</div>
        <div>epic together!</div>
      </div>
      <div className="c-application contact">
        <span>Contact Form</span>
      </div>
      <div className="form contact">
        <GridContainer elementMinWidth={300}>
          <Input name="name" placeholder="Name" />
          <Input name="surname" placeholder="Surname" />
          <Input name="phone" placeholder="Phone" />
          <Input name="mail" placeholder="E-Mail" />
        </GridContainer>
        <Input
          type="textarea"
          name="letter"
          placeholder="Brief Description of Business Need*"
        />
        <div className="form-submit contact">
          <Button>Submit</Button>
        </div>
      </div>

      <div className="c-address">
        <div className="ca-texts">
          <div className="ca-title">
            Taqafa Labs Technologies Private Limited
          </div>
          <div>
            <span>Email</span>:{" "}
            <a href="mailto:info@nextsmartmove.ai">Info@nextsmartmove.ai</a>
          </div>
          <div>
            <span>Address</span>: Address:Bootstart, Upper Ground Floor, Clover
            Hills Plaza, mall, NIBM Rd, Mohammed Wadi, Pune, Maharashtra 411048
          </div>
        </div>

        <div className="ca-icons">
          <SocialIcon
            index={1}
            url="https://www.linkedin.com/company/next-smart-move/"
          >
            <Linkedin />
          </SocialIcon>
          <SocialIcon index={2} url="https://youtube.com">
            <Youtube />
          </SocialIcon>
          <SocialIcon
            index={3}
            url="https://www.instagram.com/nextsmartmove_official?igsh=M3JuZmVoMWUyNmls"
          >
            <Insta />
          </SocialIcon>
          <SocialIcon index={4} url="https://www.facebook.com/nextsmartmove">
            <Fb />
          </SocialIcon>
        </div>
      </div>
    </div>
  );
};

const SocialIcon = ({ url = "", children = <></>, index }) => {
  return (
    <div
      className={classNames("social-icon-box", `sib${index}`)}
      onClick={() => {
        window.open(url, "_blank");
      }}
    >
      {children}
    </div>
  );
};

export { Contact, Contact2 };
