import React, { useState } from "react";
import "./Navbar.scss";

// assets
import Logo from "../../assets/logo-white.png";
import { classNames } from "../../services/util";
import { Contact, Contact2 } from "../Contact/Contact";

const Navbar = () => {
  const [active, setActive] = useState(false);
  return (
    <>
      <div className="navbar-comp">
        <div className="nc-logo">
          <img className="nc-image" src={Logo} alt="" />
          <div className="nc-line"></div>
          <div className="nc-content">
            <span>Next Smart Move</span>
          </div>
        </div>

        <div
          onClick={() => setActive((o) => !o)}
          className={classNames("nc-ham", active ? "active" : "")}
        >
          <div className="nch-bar bar1"></div>
          <div className="nch-bar bar2"></div>
          <div className="nch-bar bar3"></div>
          <div className="spot-spreader"></div>
        </div>
      </div>

      {active && <NavPage />}
    </>
  );
};

const NavPage = () => {
  const [activePage, setActivePage] = useState(0);
  return (
    <div className="navbar-page">
      <div className="np-sections">
        <div className="np-left">
          <div
            onClick={() => setActivePage(0)}
            className={classNames("np-nav", activePage === 0 ? "active" : "")}
          >
            <span>Career</span>
          </div>
          <div
            onClick={() => setActivePage(1)}
            className={classNames(
              "np-nav npn2",
              activePage === 1 ? "active" : ""
            )}
          >
            <span>Contact</span>
          </div>
        </div>
        <div className="np-right">
          {activePage == 0 ? <Contact /> : <Contact2 />}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
