import React from "react";

const GridContainer = ({
  children,
  style = {},
  elementMinWidth = 200,
  columnGap = 10,
  rowGap = 10,
}) => {
  return (
    <div
      className="grid-container-comp"
      style={{
        display: "grid",
        gridTemplateColumns: `repeat(auto-fill, minmax(${elementMinWidth}px, 1fr))`,
        columnGap,
        rowGap,
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export default GridContainer;
