import React from "react";
import "./Input.scss";
import { classNames } from "../../services/util";

const Input = ({
  value = "",
  onChange = () => {},
  placeholder = "Fill the field",
  type = "text",
  name = "",
}) => {
  return (
    <div className={classNames("input-comp", type)}>
      {type == "textarea" ? (
        <textarea name={name} className="ic-input" placeholder={placeholder} />
      ) : (
        <input
          className="ic-input"
          name={name}
          // value={value}
          // onChange={(e) => onChange(e.target.value)}
          placeholder={placeholder}
        />
      )}
    </div>
  );
};

export default Input;
