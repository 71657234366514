export function classNames() {
  let classString = "";
  for (const argument of arguments) {
    if (argument) {
      if (classString === "") {
        classString = `${argument}`;
      } else {
        classString = classString + ` ${argument}`;
      }
    }
  }

  return classString;
}

export function getTimeRemaining(targetDate) {
  // Get the current date and time
  const now = new Date();

  // Calculate the difference in milliseconds
  const timeDifference = targetDate - now;

  // Ensure the difference is non-negative
  if (timeDifference <= 0) {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }

  // Convert the difference to seconds
  const seconds = Math.floor(timeDifference / 1000);

  // Calculate days, hours, minutes, and remaining seconds
  const days = Math.floor(seconds / (24 * 60 * 60));
  const hours = Math.floor((seconds % (24 * 60 * 60)) / (60 * 60));
  const minutes = Math.floor((seconds % (60 * 60)) / 60);
  const remainingSeconds = seconds % 60;

  // Return the result as an object
  return {
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: remainingSeconds,
  };
}
