/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 rockandcrystal/rockandcrystal.gltf 
*/

import React, { useEffect, useRef, useState } from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import * as THREE from "three";
// import second from "./rockandcrystal/"

export default function Model(props) {
  const { nodes, materials } = useGLTF(
    "models/rockandcrystal/rockandcrystal.gltf"
  );
  const group = useRef();
  const container = useRef();

  // Track the mouse position
  const mouse = useRef({ x: 0, y: 0 });

  // const handleMouseMove = (event) => {
  //   mouse.current.x = (event.clientX / window.innerWidth) * 2 - 1;
  //   mouse.current.y = -(event.clientY / window.innerHeight) * 2 + 1;
  // };

  // React.useEffect(() => {
  //   window.addEventListener("mousemove", handleMouseMove);
  //   return () => window.removeEventListener("mousemove", handleMouseMove);
  // }, []);

  useFrame(({ clock }) => {
    // Create a floating effect using sine wave based on the elapsed time
    const time = clock.getElapsedTime();
    // group.current.position.y = Math.sin(time) * 0.1; // Adjust the amplitude (0.5) for a stronger or weaker effect
    group.current.rotation.y = Math.sin(time) * 0.05; // Adjust the amplitude (0.5) for a stronger or weaker effect

    // mouse controls
    // const targetRotation = new THREE.Euler(
    //   mouse.current.y * 0.2, // Adjust these values to control sensitivity
    //   mouse.current.x * 0.2,
    //   0
    // );
    // // Smoothly interpolate the current rotation to the target rotation
    // container.current.rotation.x = THREE.MathUtils.lerp(
    //   group.current.rotation.x,
    //   targetRotation.x,
    //   0.1
    // );
    // container.current.rotation.y = THREE.MathUtils.lerp(
    //   group.current.rotation.y,
    //   targetRotation.y,
    //   0.1
    // );
  });

  const responsivePosition = () => {
    const width = window.innerWidth;

    if (width > 1200) {
      return {
        position: [-2, 1.0, -0.067],
        scale: 0.7,
        rotation: [0.134, -0.172, 2.749],
      };
    }
    return {
      position: [0.8, 2, -0.067],
      scale: 0.5,
      rotation: [0.134, -0.172, 2.749],
    };
  };

  return (
    <group ref={container} {...props} dispose={null}>
      <group
        ref={group}
        position={responsivePosition().position}
        rotation={responsivePosition().rotation}
        scale={responsivePosition().scale}
      >
        <mesh geometry={nodes.Cube001.geometry} material={materials.Material} />
        <mesh
          geometry={nodes.Cube001_1.geometry}
          material={materials["boulder_01.001"]}
        />
      </group>
      {/* <Rig /> */}
    </group>
  );
}

const Rig = () => {
  const { camera, mouse } = useThree();
  console.log("mouse", mouse);
  const vec = new THREE.Vector3();
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (event) => {
    // Normalizing mouse position (-1 to 1 range for both axes)
    setMousePosition({
      x: (event.clientX / window.innerWidth) * 2 - 1,
      y: -(event.clientY / window.innerHeight) * 2 + 1,
    });
  };

  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return useFrame(() =>
    camera.position.lerp(
      vec.set(-mousePosition.x * 1, -mousePosition.y * 1, camera.position.z),
      0.02
    )
  );
};

useGLTF.preload("models/rockandcrystal/rockandcrystal.gltf");
